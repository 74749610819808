//@flow
import {toast} from "react-toastify";
import type {NOTIFY_MESSAGE} from "../../FlowFramework/Handler/SubscribeToSocket";

export let NotificationConfig = {
  showMessages: (messages: [NOTIFY_MESSAGE],auto) => {
    let mainMessages = messages['main'] || [];
    mainMessages.forEach((message: NOTIFY_MESSAGE) => {
      (toast[message.type])(message.description);
    });
  },
};
