import React, {Component, Fragment, Suspense} from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import Loadable from 'react-loadable';
import {Container, Row} from "reactstrap";
import {AuthHelper} from "../Helpers/Auth";
import type {IAutoMessage, ISCM, REQUEST, RESPONSE} from "../../FlowFramework/Handler/SCM";
import SCM from "../../FlowFramework/Handler/SCM";
import {MainHelper} from "../Helpers/Main";
import {ToastContainer} from "react-toastify";
import {Color} from "../../resources/style/Color";
import {HRH} from "../../Framework/Helpers/HashRouterHelper";


const ReactLoading = React.lazy(() => import('react-loading'));
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;


// LANG Containers
const LTR = Loadable({
  loader: () => import('./LTR'),
  loading
});
const RTL = Loadable({
  loader: () => import('./RTL'),
  loading
});


// Containers
const DefaultLayout = Loadable({
  loader: () => import('../UI/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('../Activities/Login'),
  loading
});

const Page404 = Loadable({
  loader: () => import('../Activities/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('../Activities/Page500'),
  loading
});

const Page403 = Loadable({
  loader: () => import('../Activities/Page403'),
  loading
});

class App extends Component implements ISCM {

  scm: SCM = null;

  constructor(props) {
    super(props);
    super(props);
    this.state = {
      ready: false,
    };
    this.router = React.createRef();
    this.scm = new SCM(this);
    //get saved data
    this.optSavedData();
    //get and set language
    MainHelper.startLang();
  }

  optSavedData() {
    MainHelper.optDataFromLocalStorage();
    AuthHelper.optDataFromLocalStorage();
  }

  componentDidMount(): void {
    //login with token --> go
    setTimeout(() => {
      this.scm.post({
        Tag: 'start',
        action: 'auth/start',
      });
    }, 2000);
  }


  SCM_onRequestSubmit(req: REQUEST): Boolean {
    return true;
  }

  SCM_onSuccess(req: REQUEST, res: RESPONSE): IAutoMessage {
    if (req.Tag === 'start') {
      if (res.valid && res.exe) {
        //start
        AuthHelper.start(res.data);
        this.setState({ready: true});
      } else {
        AuthHelper.logout();
        this.setState({ready: true},()=>{
          HRH.go('/login');
        });
      }
      return () => null;
    }
    return (base) => base;
  }

  SCM_onFailure(req: REQUEST, ident: string, err: string): IAutoMessage {
    if (req.Tag === 'start') {
      AuthHelper.logout();
      this.setState({ready: true},()=>{
        HRH.go('/login');
      });
      return () => null;
    }
    return (base) => base;
  }

  render() {
    let Component = MainHelper.getLang() !== 'ar' ? LTR : RTL;
    return (
      <Component>
        {
          (this.state.ready) ?
            (
              <Fragment>
                <HashRouter ref={ref => {
                  this.router = ref;
                  HRH.init(this.router);
                }}>
                  <Switch>
                    <Route exact path="/login" name="Login Page" component={Login}/>
                    <Route exact path="/404" name="Page 404" component={Page404}/>
                    <Route exact path="/500" name="Page 500" component={Page500}/>
                    <Route exact path="/403" name="Page 403" component={Page403}/>
                    <Route path="/" name="Home" component={DefaultLayout}/>
                  </Switch>
                </HashRouter>
                <ToastContainer/>
              </Fragment>
            )
            :
            (
              <div className="app flex-row align-items-center">
                <Container>
                  <Row className="justify-content-center">
                    <Suspense fallback={null}>
                      <ReactLoading type={'bubbles'} color={Color.loading}/>
                    </Suspense>
                  </Row>
                </Container>
              </div>
            )
        }
      </Component>
    );


  }
}

export default App;
