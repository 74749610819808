import Caller from "../../FlowFramework/Handler/Caller";
import type {REQUEST} from "../../FlowFramework/Handler/SCM";

export let Callers = {
  //statistics
  statistics: new Caller({
    data: {
      clients: {
        all: 0,
        male: 0,
        female: 0,
        blocked: 0,
        online: {
          all: 0,
          male: 0,
          female: 0,
        },
        register:{
          day:0,
          week:0,
          month:0,
        }
      },
      subscriptions: {
        free: 0,
        gold: 0,
        vip: 0,
      },
      messages: {
        visitors: {
          today: [],
          st: 0
        },
        reports: {
          today: [],
          st: 0
        },
      },
      payments: 0,
      orders: 0,
    },
    remoteScmRequest: (self: Caller, remoteWith: any): REQUEST => {
      return {
        method: 'get',
        action: 'home/statistics',
      }
    },
  }),

  //users
  users: new Caller({
    data: {
      t: 0,
    },
  }),
};
