import {SubscribeToSocket} from "../../FlowFramework/Handler/SubscribeToSocket";
import SCM from "../../FlowFramework/Handler/SCM";

let Store = {
  token: null,
  username: null,
  email: null,
  notifyToken: null,
  notifyChannels: ['admin'],
  permissions: {},
};

export let AuthHelper = {
  optDataFromLocalStorage: () => {
    Store.token = localStorage.getItem('token');
    Store.username = localStorage.getItem('username');
    Store.email = localStorage.getItem('email');
    Store.notifyToken = localStorage.getItem('notifyToken');
  },
  saveDataToLocalStorage: () => {
    localStorage.setItem('token', Store.token);
    localStorage.setItem('username', Store.username);
    localStorage.setItem('email', Store.email);
    localStorage.setItem('notifyToken', Store.notifyToken);
  },
  getToken: () => {
    return Store.token;
  },
  getNotifyToken: () => {
    return Store.notifyToken;
  },
  getNotifyChannels: () => {
    return Store.notifyChannels;
  },
  getUserName: () => {
    return Store.username;
  },
  getPermissions: () => {
    return Store.permissions;
  },
  isLogin: () => {
    return Store.token !== null;
  },
  login: (data) => {
    Store.token = data.token;
    Store.username = data.username;
    Store.email = data.email;
    Store.notifyToken = data.notifyToken;
    AuthHelper.saveDataToLocalStorage();
    AuthHelper.start(data);
  },
  start: (data) => {
    //permissions
    Store.permissions = data.permissions;
    //then
    SubscribeToSocket();
  },
  logout: (showLogoutToast = false) => {
    let localLogout = () => {
      Store.token = null;
      Store.username = null;
      Store.email = null;
      Store.notifyToken = null;
      AuthHelper.saveDataToLocalStorage();
    };
    (new SCM({
      SCM_onSuccess: (req, res) => {
        if (showLogoutToast !== true) {
          localLogout();
          return base => null;
        }
        return base => {
          base.overrideMessageProps = {
            closeable: false,
            onClose: () => {
              localLogout();
            },
          };
          return base;
        };
      },
      SCM_onFailure: (req, ident, err) => {
        localLogout();
        return base => base;
      },
      SCM_onRequestSubmit: (req) => true,
    })).get({
      action: 'auth/logout'
    });
  },
  // if action = undefined ==> I check at least one in ( section )
  can: (section = undefined, action = undefined, yesCall = undefined, noCall = undefined) => {
    let can = (section === undefined && action === undefined) ||
      (Store.permissions[section] !== undefined && (action === undefined || Store.permissions[section][action] !== undefined));
    if (can) {
      if (yesCall !== undefined) {
        return yesCall();
      }
    } else {
      if (noCall !== undefined) {
        return noCall();
      }
    }
    return can;
  }
};
