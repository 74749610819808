import type {NOTIFICATION} from "../../FlowFramework/Handler/SubscribeToSocket";
import {Callers} from "./Callers";

export let Notifications = {
  newClientRegister: (notification: NOTIFICATION) => {
    //register here callers
    Callers.statistics.remoteUpdate();
    Callers.users.update({
      t: notification.data.time,
    });

    return base => base;
  },
  newOrder: (notification: NOTIFICATION) => {
    //register here callers
    //...
    Callers.statistics.remoteUpdate();

    return base => base;
  },
  newGoldOrder: (notification: NOTIFICATION) => {
    //register here callers
    //...
    Callers.statistics.remoteUpdate();

    return base => base;
  },
  newGatePayment: (notification: NOTIFICATION) => {
    //register here callers
    //...
    Callers.statistics.remoteUpdate();

    return base => base;
  },
  newTicket: (notification: NOTIFICATION) => {
    //register here callers
    //...
    Callers.statistics.remoteUpdate();

    return base => base;
  },
  newTicketMessage: (notification: NOTIFICATION) => {
    //register here callers
    //...
    Callers.statistics.remoteUpdate();

    return base => base;
  },
  newVisitorMessage: (notification: NOTIFICATION) => {
    //register here callers
    //...
    Callers.statistics.remoteUpdate();

    return base => base;
  },
  newReportMessage: (notification: NOTIFICATION) => {
    //register here callers
    //...
    Callers.statistics.remoteUpdate();

    return base => base;
  },


};
