//@flow
import SCM, {ERROR,IAutoMessage,REQUEST,RESPONSE} from "../../FlowFramework/Handler/SCM";
import {toast} from "react-toastify";
import {HRH} from "../../Framework/Helpers/HashRouterHelper";

export let ScmConfig = {
  langKey:'adminpanel---lang',
    networkTest: (scm:SCM,then) =>  {
        console.log(`network test`);
            then(true);
    },
    successExeMessage:(scm:SCM,req:REQUEST,res:RESPONSE,auto:IAutoMessage)=>{
      (toast[res.mainExeMessages[0].type])(res.mainExeMessages[0].description);
    },
    successValidMessage:(scm:SCM,req:REQUEST,res:RESPONSE,auto:IAutoMessage)=>{
      (toast[res.mainValidMessages[0].type])(res.mainValidMessages[0].description);
    },
    failureMessages:(scm:SCM,req: REQUEST, ident: string, err: string,auto:IAutoMessage)=>{
        switch (err) {
            case ERROR.NO_LOGIN:
                // show message , must login , then hide after 3 sec
              HRH.go('/login');
                break;
            case ERROR.NO_PERMISSION:
                // show message , no permission , then hide after 3 sec
              HRH.go('/403');
                break;
            case ERROR.SERVER_ERROR:
                // retry after 5 sec
              HRH.go('/500');
                break;
            case ERROR.NO_NETWORK:
                // show message , no network , with one button to retry connect
              toast.error('Please check your internet connection');
                break;
            case ERROR.INTERNAL_ERROR:
                // console only
                console.log(`request ${req.Tag} INTERNAL_ERROR`);
                break;
            case ERROR.RECEIVE_DATA_ERROR:
                // console only
                console.log(`request ${req.Tag} RECEIVE_DATA_ERROR`);
                break;
        }
    },
};
