//@flow
import Axios from 'axios';
import {MainHelper} from "../../App/Helpers/Main";
import {AuthHelper} from "../../App/Helpers/Auth";
import {ScmConfig} from "../../App/Configs/ScmConfig";
import {tools} from "../Helpers/tools";

export default class SCM {

    /* *************************************************************
    ****************************************************************
                           ** constructor **
    ****************************************************************
    ****************************************************************
    ************************************************************* */

    subscribed = false;

    constructor(listener: ISCM) {
        if (listener !== undefined && listener !== null) {
            this.listener = listener;
        }
        this.subscribed = true;
    }

    static new(listener: ISCM) {
        return new SCM(listener);
    }


    /* *************************************************************
    ****************************************************************
                           ** public **
     public -> private -> localCaller -> localListener -> listener
    ****************************************************************
    ****************************************************************
    ************************************************************* */

    get(req: REQUEST) {
        this.request({...req, method: 'get'});
    }

    post(req: REQUEST) {
        this.request({...req, method: 'post'});
    }

    async a_get(req: REQUEST) {
        await this.a_request({...req, method: 'get'});
    }

    async a_post(req: REQUEST) {
        await this.a_request({...req, method: 'post'});
    }

    unsubscribe() {
        this.subscribed = false;
    }


    /* *************************************************************
    ****************************************************************
                           ** private **
    ****************************************************************
    ****************************************************************
    ************************************************************* */

    _config(req: REQUEST) {
        return {
            withCredentials: ScmConfig.withCredentials === true,
            baseURL: MainHelper.getApi(),
            timeout: req.timeout || 30000,
            headers: {
                ...(req.sendAsMultipart === true ? {'content-type': 'multipart/form-data'} : {}),
                ...(AuthHelper.getToken() !== undefined ? {[ScmConfig.tokenKey !== undefined ? (typeof ScmConfig.tokenKey === 'function' ? ScmConfig.tokenKey(this) : ScmConfig.tokenKey) : 'token']: AuthHelper.getToken()} : {}),
                ...(MainHelper.getLang() !== undefined ? {[ScmConfig.langKey !== undefined ? (typeof ScmConfig.langKey === 'function' ? ScmConfig.langKey(this) : ScmConfig.langKey) : 'lang']: MainHelper.getLang()} : {}),
                ...MainHelper.additionalHeaders(),
                ...req.header,
            },
            url: req.action + (req.getParameters !== undefined && req.getParameters.length > 0 ? ('/' + (req.getParameters || []).join('/')) : ''),
            method: req.method,
            params: tools.checkoutParams(req.queryParameters),
            data: (() => {
                if (req.sendAsMultipart === true) {
                    let data = new FormData();
                    Object.keys(req.postParameters).forEach((key) => {
                        data.append(key, req.postParameters[key]);
                    });
                    return data;
                } else {
                    return req.postParameters || undefined;
                }
            })(),
        };
    }

    request(req: REQUEST) {
        tools.console(`request ${req.Tag} will send`);
        req.isAsync = false;
        if (this.listener.SCM_onRequestSubmit(req) === false) {
            return;
        }
        //::linker::
        if (ScmConfig.submitLinker) {
            ScmConfig.submitLinker(req);
        }
        ScmConfig.networkTest(this, (isConnected) => {
            if (isConnected === true) {
                tools.console(`success network test`);
                //send request
                Axios.request(this._config(req))
                    .then((response) => {
                        //::linker::
                        if (ScmConfig.responseLinker) {
                            ScmConfig.responseLinker(req, response);
                        }
                        tools.console(`${req.Tag} response`, response);
                        if (!this.subscribed) {
                            return;
                        }
                        this.successProcessing(req, response);
                    })
                    .catch((e) => {
                        tools.console(`ERR/121: ${e.message}`);
                        if (!this.subscribed) {
                            return;
                        }
                        this.onFailure(req, 'default', ERROR.SERVER_ERROR);
                    });
            } else {
                //failure , no network
                this.onFailure(req, 'default', ERROR.NO_NETWORK);
            }
        });
    }

    async a_request(req: REQUEST) {
        tools.console(`request ${req.Tag} will send`);
        req.isAsync = true;
        if (this.listener.SCM_onRequestSubmit(req) === false) {
            return;
        }
        //::linker::
        if (ScmConfig.submitLinker) {
            ScmConfig.submitLinker(req);
        }
        let isConnected = await ScmConfig.a_networkTest(this);
        if (isConnected === true) {
            tools.console(`${req.Tag} success network test`);
            //send request
            try {
                let response = await Axios.request(this._config(req));
                //::linker::
                if (ScmConfig.responseLinker) {
                    ScmConfig.responseLinker(req, response);
                }
                if (!this.subscribed) {
                    return;
                }
                this.successProcessing(req, response);
            } catch (e) {
                tools.console(`ERR/158: ${e.message}`);
                if (!this.subscribed) {
                    return;
                }
                this.onFailure(req, 'default', ERROR.SERVER_ERROR);
            }
        } else {
            //failure , no network
            this.onFailure(req, 'default', ERROR.NO_NETWORK);
        }
    }

    successProcessing(req: REQUEST, response) {
        tools.console(`request ${req.Tag} will success processing`);
        try {
            Object.keys(response.data).forEach((ident) => {
                let _response = response.data[ident];
                this.successProcessingForAllIdents(req, ident, _response, response);
            });
        } catch (e) {
            tools.console(`ERR/178: ${e.message}`);
            //TODO : here I need to show originalResponse also !!
            this.onFailure(req, 'default', ERROR.RECEIVE_DATA_ERROR);
        }
    }

    successProcessingForAllIdents(req: REQUEST, ident, response, originalResponse) {
        tools.console(`ident ${ident} will success processing`);
        let res: RESPONSE = {};
        res.originalResponse = originalResponse;
        res.ident = ident;
        res.resultCode = response.out.result.code;
        res.valid = response.out.result.valid === 'success';
        res.exe = response.out.result.exe === 'success';
        res.isSuccess = res.valid && res.exe;

        res.validMessages = (name) => {
            if (name === undefined) {
                return response.out.messages.validation;
            }
            return response.out.messages.validation[name] || [];
        };

        res.exeMessages = (name) => {
            if (name === undefined) {
                return response.out.messages.execution;
            }
            return response.out.messages.execution[name] || [];
        };

        res.mainExeMessages = response.out.messages.execution.main || [];
        res.mainValidMessages = response.out.messages.validation.main || [];
        if (req.dataParseFunc !== undefined) {
            res.data = req.dataParseFunc(response.out.data)
        } else {
            res.data = response.out.data;
        }
        res.inputs = (name) => {
            if (name === undefined) {
                return response.out.inputs;
            }
            if (response.out.inputs[name] !== undefined) {
                return response.out.inputs[name];
            }
            return undefined;
        };
        switch (res.resultCode) {
            case 456:
                this.onFailure(req, ident, ERROR.NO_LOGIN);
                break;
            case 231:
                this.onFailure(req, ident, ERROR.NO_PERMISSION);
                break;
            case 1:
                try {
                    this.onSuccess(req, res);
                } catch (e) {
                    tools.console(`ERR/235: ${e.message}`);
                    this.onFailure(req, ident, ERROR.INTERNAL_ERROR);
                }
                break;
        }
    }

    onSuccess(req: REQUEST, res: RESPONSE) {
        tools.console(`request ${req.Tag} is success`);
        let auto = this.listener.SCM_onSuccess(req, res)(this.baseListener.SCM_onSuccess(req, res));
        if (auto !== null) {
            //exe main message
            if (res.mainExeMessages.length > 0) {
                //show message
                ScmConfig.successExeMessage(this, req, res, auto);
            }
            //valid main message
            if (res.mainValidMessages.length > 0) {
                //show message
                ScmConfig.successValidMessage(this, req, res, auto);
            }
        }
    }

    onFailure(req: REQUEST, ident: string, err: string) {
        tools.console(`request ${req.Tag} is failed with error ${err}`);
        if (req.tryTimes !== undefined && req.tryTimes > 0 && tools.inArray(err, [
            ERROR.INTERNAL_ERROR,
            ERROR.NO_NETWORK,
            ERROR.RECEIVE_DATA_ERROR,
            ERROR.SERVER_ERROR
        ])) {
            req.tryTimes -= 1;
            if (req.isAsync) {
                this.a_request(req);
            } else {
                this.request(req);
            }
            return;
        }
        let auto = this.listener.SCM_onFailure(req, ident, err)(this.baseListener.SCM_onFailure(req, ident, err));
        if (auto !== null) {
            ScmConfig.failureMessages(this, req, ident, err, auto);
        }
    }


    /* *************************************************************
    ****************************************************************
                           ** listeners **
    ****************************************************************
    ****************************************************************
    ************************************************************* */
    baseListener: ISCM = {
        //default set
        SCM_onRequestSubmit: (req: REQUEST) => {
            return true;
        },
        SCM_onSuccess: (req: REQUEST, res: RESPONSE) => {
            return {
                overrideMessageProps: {},
            };
        },
        SCM_onFailure: (req: REQUEST, ident: string, err: string) => {
            return {
                overrideMessageProps: {},
            };
        },
    };

    listener: ISCM = {
        SCM_onRequestSubmit: (req: REQUEST) => {
            return true;
        },

        SCM_onSuccess: (req: REQUEST, res: RESPONSE) => {
            return base => base;
        },

        SCM_onFailure: (req: REQUEST, ident: string, err: string) => {
            return base => base;
        },
    };
}

export const ERROR = {
    NO_NETWORK: 'NO_NETWORK',
    NO_PERMISSION: 'NO_PERMISSION',
    NO_LOGIN: 'NO_LOGIN',
    SERVER_ERROR: 'SERVER_ERROR',
    INTERNAL_ERROR: 'INTERNAL_ERROR',
    RECEIVE_DATA_ERROR: 'RECEIVE_DATA_ERROR',
};

export interface REQUEST {
    Tag?: string;
    instance: INSTANCE,
    method?: string;
    action?: string;
    header?: any;
    getParameters?: Array;
    queryParameters?: any;
    postParameters?: any;
    sendAsMultipart: boolean,
    dataParseFunc?: (data)=>{},
    timeout?: any,
    tryTimes?: any,
    isAsync?: boolean,
}

export interface RESPONSE {
    originalResponse: any,
    ident: string,
    resultCode: any;
    isSuccess: Boolean;
    valid: string;
    exe: string;
    mainExeMessages: [],
    mainValidMessages: [],
    validMessages: (name: string) => [];
    exeMessages: (name: string) => [];
    data: {};
    inputs: (name: string) => {};
}

export interface INSTANCE {
    senderType: string,
    senderElement: any,
    //and any other
}

export interface HELP_MESSAGE {
    code: any,
    type: any,
    title: any,
    description: any,
}

export interface IAutoMessage {
    overrideMessageProps: {},
}

export interface ISCM {
    SCM_onRequestSubmit(req: REQUEST): Boolean;

    SCM_onSuccess(req: REQUEST, res: RESPONSE): IAutoMessage;

    SCM_onFailure(req: REQUEST, ident: string, err: string): IAutoMessage;
}
