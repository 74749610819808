import {tools} from "../../FlowFramework/Helpers/tools";


let apiBase = 'https://hilike.org';

let Store = {
  api_public: apiBase +'/backend/public/',
  api: apiBase + '/backend/public/adminpanel/',
  socket_api: apiBase,
  defaultLang: 'en',
  lang: 'en',
  timezoneOffset: new Date().getTimezoneOffset() * 60,
  tablePageSize: 20,
};

export let MainHelper = {
  optDataFromLocalStorage: () => {
    //tablePageSize
    let tablePageSize = localStorage.getItem('tablePageSize');
    if (tools.inArray(tablePageSize, ['undefined', undefined, null, 0])) {
      localStorage.setItem('tablePageSize', Store.tablePageSize);
    }
    Store.tablePageSize = tablePageSize;
    //lang
    let lang = localStorage.getItem('lang');
    if (tools.inArray(lang, ['undefined', undefined, null, ''])) {
      localStorage.setItem('lang', Store.defaultLang);
    }
    Store.lang = lang;
  },
  saveDataToLocalStorage: () => {
    localStorage.setItem('tablePageSize', Store.tablePageSize);
    localStorage.setItem('lang', Store.lang);
  },
  getApi: () => {
    return Store.api;
  },
  imageUrl: (image, size = 'thumb') => {
    let url = Store.api_public + '/upload/images/';
    if (size === "default") {
      return url + image;
    } else {
      return url + size + "/" + image;
    }
  },
  getSocketApi: () => {
    return Store.socket_api;
  },
  startLang: () => {
    let lang = Store.lang;
    document.getElementsByTagName("html")[0].lang = lang;
    document.getElementsByTagName("html")[0].dir = lang !== 'ar' ? 'ltr' : 'rtl';
  },
  changeLang: () => {
    Store.lang = Store.lang === 'en' ? 'ar' : 'en';
    MainHelper.saveDataToLocalStorage();
    window.location.reload(true);
  },
  getDefaultLang: () => {
    return Store.defaultLang;
  },
  getLang: () => {
    return Store.lang;
  },
  getTablePageSize: () => {
    return Store.tablePageSize;
  },
  setTablePageSize: (size) => {
    Store.tablePageSize = size;
    MainHelper.saveDataToLocalStorage();
  },
  additionalHeaders: () => {
    return {
      timezoneOffset: Store.timezoneOffset,
    };
  },
};
