import {tools} from "../../FlowFramework/Helpers/tools";

export let HRH = {
  init: (router) => {
    HRH.go = (to, newTab = false, search = undefined) => {
      if (!newTab) {
        router.history.push(to);
      } else {
        let _search = search !== undefined ? '?h=' + btoa(tools.escape(JSON.stringify(search))) : '';
        window.open(`#${to}${_search}`, '_blank')
      }
    };
    HRH.push = (pathname, search, state) => {
      router.history.push({pathname, search, state});
    };
    HRH.replace = (pathname, search, state) => {
      router.history.replace({pathname, search, state});
    };
    HRH.router = () => {
      return router;
    };
    HRH.goBack = () => {
      router.history.goBack();
    };
  },
  go: (to, newTab = false, search = undefined) => {
  },
  push: (pathname, search, state) => {
  },
  replace: (pathname, search, state) => {
  },
  router: () => {
  },
  goBack: () => {
  },
  withSearch: (to, search) => {
    let _search = search !== undefined ? '?h=' + btoa(tools.escape(JSON.stringify(search))) : '';
    return `${to}${_search}`;
  },
  upSaver: (contextProps, Tag, call: ((now: any)=>{})) => {
    try {
      let now = contextProps.location.search !== '' ? JSON.parse(tools.unescape(atob(contextProps.location.search.substr(3)))) : {};
      now[Tag] = now[Tag] || {};
      call(now[Tag]);
      HRH.replace('', '?h=' + btoa(tools.escape(JSON.stringify(now))));
    } catch (e) {
      tools.console('upSaver', e);
    }
  },
  optSaver: (contextProps, Tag) => {
    try {
      let now = contextProps.location.search !== '' ? JSON.parse(tools.unescape(atob(contextProps.location.search.substr(3)))) : {};
      return now[Tag] || {};
    } catch (e) {
      tools.console('upSaver', e);
      return {};
    }
  },
};
