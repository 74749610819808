import type {IAutoMessage, ISCM, REQUEST, RESPONSE} from "./SCM";
import SCM from "./SCM";

export default class Caller extends Object implements ISCM {

    listeners = {};
    config: CallerConfig = {};
    initData: CallerConfig = {};
    scm: SCM = null;

    constructor(config: CallerConfig) {
        super();
        this.config = config || {};
        this.initData = this.config.data || {};
    }

    addListener(listener: LISTENER, withRaiseMe = false) {
        this.listeners[listener.ident] = listener;
        if (withRaiseMe === true) {
            this.listeners[listener.ident].onUpdate(this.config.data);
        }
    }

    removeListener(ident: string) {
        if (ident !== undefined) {
            this.listeners[ident] = null;
        } else {
            this.listeners = {};
        }
    }

    remoteUpdate(remoteWith = undefined, iscm: ISCM = undefined) {
        this.scm = new SCM(this);
        this.scm.request({...this.config.remoteScmRequest(this, remoteWith), ...(iscm !== undefined ? {iscm} : {})});
    }

    resetData(withRaiseEvent = false) {
        this.config.data = this.initData;
        if (withRaiseEvent === true) {
            this.raiseEvent();
        }
    }

    update(data) {
        try {
            if (typeof data === 'function') {
                data(this.config.data);
            } else {
                this.config.data = {...this.config.data, ...data};
            }
            this.raiseEvent();
        } catch (e) {
        }
    }

    getData(key = undefined, _default = undefined) {
        if (key === undefined) {
            return this.config.data;
        } else {
            try {
                return typeof key === 'function' ? key(this.config.data, this) : this.config.data[key];
            } catch (e) {
                //if there error
                console.log(e);
                return typeof _default === 'function' ? _default(this) : _default;
            }
        }
    }

    setData(key, value = undefined, _defaultReturn = undefined) {
        try {
            if (typeof key === 'function') {
                return key(this.config.data, this);
            } else {
                this.config.data[key] = value;
                return value;
            }
        } catch (e) {
            //if there error
            console.log(e);
            return _defaultReturn;
        }
    }

    raiseEvent() {
        if (this.config.willRaiseEvent === undefined || this.config.willRaiseEvent(this) !== false) {
            Object.keys(this.listeners).forEach((ident) => {
                if (this.listeners[ident] !== undefined && this.listeners[ident] !== null) {
                    this.listeners[ident].onUpdate(this.config.data);
                }
            });
        }
    }

    _(fn, _default = undefined) {
        try {
            return this.config.call(this)[fn];
        } catch (e) {
            return () => _default;
        }
    }


    //virtual type
    vUpdate(event, data) {
        try {
            this.vRaiseEvent(event, data);
        } catch (e) {
        }
    }

    vRaiseEvent(event, data) {
        Object.keys(this.listeners).forEach((ident) => {
            if (this.listeners[ident] !== undefined && this.listeners[ident] !== null) {
                this.listeners[ident].onUpdate(event, data);
            }
        });
    }

    /* *************************************************************
    ****************************************************************
                           ** scm **
    ****************************************************************
    ****************************************************************
    ************************************************************* */

    SCM_onRequestSubmit(req: REQUEST): Boolean {
        if (req.iscm !== undefined && req.iscm.SCM_onRequestSubmit !== undefined) {
            return req.iscm.SCM_onRequestSubmit(req);
        }
        return true;
    }

    SCM_onFailure(req: REQUEST, ident: string, err: string): IAutoMessage {
        if (req.iscm !== undefined && req.iscm.SCM_onFailure !== undefined) {
            return req.iscm.SCM_onFailure(req, ident, err);
        }
        return base => null;
    }

    SCM_onSuccess(req: REQUEST, res: RESPONSE): IAutoMessage {
        if (res.valid && res.exe) {
            //callers base
            let baseSet = {};
            Object.keys(this.config.data).forEach((key) => {
                if (res.data[key] !== undefined) {
                    baseSet[key] = res.data[key];
                }
            });
            //go
            if (this.config.remoteSetDataFunc !== undefined) {
                this.config.remoteSetDataFunc(this, res, (fn: ((baseSet: {})=>{})) => {
                    let newData = fn(baseSet); // return base or other
                    this.config.data = {...this.config.data, ...newData};
                    this.raiseEvent();
                });
            } else {
                this.config.data = {...this.config.data, ...baseSet};
                this.raiseEvent();
            }

        }
        if (req.iscm !== undefined && req.iscm.SCM_onSuccess !== undefined) {
            return req.iscm.SCM_onSuccess(req, res);
        }
        return base => null;
    }


}

export interface CallerConfig {
    data: {},
    remoteScmRequest: (self: Caller, remoteWith: any)=>REQUEST,
    remoteSetDataFunc: (self: Caller, res: RESPONSE, apply: (fn: (baseSet: {})=>{})=>void)=>{},
    willRaiseEvent: (self: Caller)=>boolean,
    call: (self: Caller)=>{},
}

export interface LISTENER {
    ident: string,
    onUpdate: (data)=>{} | (event:any, data:any)=>{} // <-- virtual type
}
