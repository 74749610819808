import openSocket from 'socket.io-client';
import {MainHelper} from "../../App/Helpers/Main";
import {AuthHelper} from "../../App/Helpers/Auth";
import {Notifications} from "../../App/Helpers/Notifications";
import {NotificationConfig} from "../../App/Configs/NotificationConfig";

const auto = {overrideMessageProps: {}};

let socket = null;

function SubscribeToSocket() {
    socket = openSocket(MainHelper.getSocketApi(), {
        timeout: 30000,
        transports: ['websocket'],
        autoConnect: true,
    });
    socket.on('disconnect', function () {
        //console.log('disconnected');
        SubscribeToSocket();
    });
    socket.on('notify', (notification: NOTIFICATION) => {
        let nt = Notifications[notification.identify];
        if (nt !== undefined) {
            let ret = nt(notification)(auto);
            if (ret !== null) {
                NotificationConfig.showMessages(notification.messages, auto);
            }
        }
    });
    socket.emit('subscribe', {token: AuthHelper.getNotifyToken(), channels: AuthHelper.getNotifyChannels()});
}

function renotify(channels:[],notification: NOTIFICATION) {
    if(socket === null)return false;
    try {
        socket.emit('renotify', {channels: channels,notification: notification});
        return true;
    }catch (e) {
        return false;
    }
}

export {SubscribeToSocket,renotify};


export interface NOTIFICATION {
    from:string,
    identify: any,
    title: any,
    code: any,
    messages: [NOTIFY_MESSAGE],
    data: {},
}

export interface NOTIFY_MESSAGE {
    code: any,
    type: any,
    title: any,
    description: any,
    onClick:any,
}
