//@flow
export let tools = {
  sleep: (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  },
  randomString: (length = 16) => {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  },
  randomWord: () => {
    let words = [
      'the',
      'a',
      'most',
      'near',
      'are',
      'can',
      'do',
      'session',
      'many',
      'man',
      'alt',
      'cost',
      'system',
      'door',
      'book',
      'salt',
      'more',
      'soon',
      'moon',
      'constant',
      'apple',
    ];
    return words[tools.rand(1, words.length - 1)];
  },
  randomText: (words = 16) => {
    let ret = '';
    for (let i = 0; i <= words - 1; i++) {
      ret = ret === '' ? tools.randomWord() : `${tools.randomWord()} ${ret} `
    }
    return ret;
  },
  rand: (_min = undefined, _max = undefined) => {
    const min = _min !== undefined ? _min : 50;
    const max = _max !== undefined ? _max : 500;
    return Math.floor(Math.random() * (max - min) + min);
  },
  randId: () => {
    return Math.floor(900000000 + Math.random() * 99999999);
  },
  bindAll: (interfaceMirror, context) => {
    Object.keys(interfaceMirror).forEach(function (key) {
      if (context[key] !== undefined && context[key].bind !== undefined) {
        context[key] = context[key].bind(context);
      }
    });
  },
  ucfirst: (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  inArray: (d, arr) => {
    let res = false;
    arr.forEach((v) => {
      if (d === v) {
        res = true;
      }
    });
    return res;
  },

  numArr: (from, to, asArray = false) => {
    let all = {};
    for (var i = from; i <= to; i++) {
      all[i] = `${i}`;
    }
    return asArray ? Object.keys(all) : all;
  },

  checkoutParams: (params, result = {}, parent = '') => {
    if (tools.inArray(params, [null, undefined]) || typeof params !== 'object') {
      return result;
    }
    Object.keys(params).forEach(key => {
      if (typeof params[key] === 'object') {
        tools.checkoutParams(params[key], result, `${parent}${parent === '' ? '' : '['}${key}${parent === '' ? '' : ']'}`);
      } else {
        if (parent === '') {
          result[key] = params[key];
        } else {
          result[`${parent}[${key}]`] = params[key];
        }
      }
    });
    return result;
  },
  tryData: (ready: ()=>{}, data: ()=>{}) => {
    return (fnc, _default = undefined) => {
      if (!ready() || Object.keys(data()).length <= 0) return _default;
      try {
        return fnc(data());
      } catch (e) {
        return _default;
      }
    };
  },
  console: (name, value) => {
    if (process.env.NODE_ENV !== "production") {
      if (value === undefined) {
        console.log(name);
      } else {
        console.log(`## ${name} START ##`);
        console.log(value);
        console.log(`## ${name} END ##`);
      }
    }
  },
  url_set: (d) => {
    return btoa(encodeURI(d))
  },
  url_get: (d) => {
    return decodeURI(atob(d || ''));
  },
  escape: (ob) => {
    let _escape = (_ob) => {
      if (typeof _ob === 'object') {
        Object.keys(_ob).forEach(key => {
          _ob[key] = _escape(_ob[key]);
        });
        return _ob;
      } else {
        return  escape(_ob);
      }
    };
    if (typeof ob === 'object') {
      return  _escape(ob);
    } else {
      return escape(ob);
    }
  },
  unescape: (ob) => {
    let _unescape = (_ob) => {
      if (typeof _ob === 'object') {
        Object.keys(_ob).forEach(key => {
          _ob[key] = _unescape(_ob[key]);
        });
        return _ob;
      } else {
        return  unescape(_ob);
      }
    };
    if (typeof ob === 'object') {
      return  _unescape(ob);
    } else {
      return unescape(ob);
    }
  },
};
